@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.entry {
  .entry-headers {
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .headers-content {
      width: 1366px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .entry-content {
    min-height: calc(100vh - 70px);
    width: 100%;
    padding-top: 70px;
    background: rgba(248, 247, 255, 1);
    display: flex;
    justify-content: center;
    .content {
      width: 1366px;
      .row1 {
        opacity: 0;
      }
      .row2 {
        margin-top: 10px;
        display: flex;
        height: calc(100vh - 60px - 150px);
        .row2-left {
          width: 20%;
          height: 100%;
          .row2-left-card {
            padding: 15px;
            height: 100%;
            .navigation-item {
              margin-top: 12px;
              padding: 12px;
              color: rgba(153, 153, 153, 1);
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .navigation-item-choose {
              margin-top: 12px;
              background: rgba(127, 118, 189, 1);
              padding: 12px;
              color: #fff;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .item-people {
              background: rgba(250, 249, 255, 1);
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .people {
                cursor: pointer;
                min-width: 100px;
                text-align: center;
                margin: 5px 0;
                padding: 4px;
                border-radius: 4px;
                font-size: 14px;
              }
              .people-choice {
                background: rgba(236, 234, 250, 1);
                font-weight: bold;
              }
            }
          }
        }
        .row2-right {
          width: calc(80% - 24px);
          margin-left: 24px;
          height: 100%;
        }
      }
    }
  }
}
